import React from 'react';
import { graphql } from 'gatsby';
import { motion, useIsPresent } from 'framer-motion';

import Layout from '../components/Layout';
import PrivateProjectContent from '../components/PrivateProject';

const PrivateProject = ({ data }) => {
  const isPresent = useIsPresent();
  const privProject = data.prismicPrivateProjects;
  console.log(privProject)
  return (
    <Layout pageId={privProject.uid}>
      <PrivateProjectContent privateProject={privProject.data} />
      <motion.div
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
        exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
        style={{ originY: isPresent ? 0 : 1 }}
        className='screen-page-transition'
      />
    </Layout>
  )
}

export default PrivateProject

export const privateQuery = graphql`
query privateProjectQuery($uid: String!) {
  prismicPrivateProjects(uid: {eq: $uid}) {
    uid
    data {
      project_name {
        text
      }
      description {
        text
      }
      company { 
        text
      }
      role {
        html
      }
      external_link {
        url
      }
      body {
        ... on PrismicPrivateProjectsDataBodyVideoLinkWithDescription {
          slice_type
          primary {
            title {
              text
            }
            description1 {
              text
            }
          }
          items {
            css_class {
              text
            }
            url {
              url
            }
          }
        }
        ... on PrismicPrivateProjectsDataBodyVideoLinkWithoutDescription {
          slice_type
          items {
            css_class {
              text
            }
            url {
              url
            }
          }
        }
        ... on PrismicPrivateProjectsDataBodyGalleryWithDescription {
          slice_type
          primary {
            title {
              text
            }
            description1 {
              text
            }
          }
          items {
            css_class {
              text
            }
            image {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        ... on PrismicPrivateProjectsDataBodyGallery {
          slice_type
          items {
            css_class {
              text
            }
            screenshot {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}`