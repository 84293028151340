import React, { Component } from 'react';
import { Link } from 'gatsby';
import { SliceZone } from '@prismicio/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from './Seo';
import { components } from '../slices/index';

import '../styles/components/_private-projects.scss';

class PrivateProjectContent extends Component {
  render() {
    return (
      <>
      <Seo title={this.props.privateProject.project_name.text} />
      <Container as='section' className='project__container'>
        <Row>
          <Col>
            <Link to='/projects'><div className='project__cta'><FontAwesomeIcon icon={ faArrowLeftLong } className='project__arrow-icon' />&nbsp;BACK TO PROJECTS</div></Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className='project__title'>{this.props.privateProject.project_name.text}</h1>
            <p className='project__description'>{this.props.privateProject.description.text}</p>
            <Row className='project__details'>
              <Col sm={4} xs={6}>
                <h4 className='project__client--title'>CLIENT</h4>
                <p className='project__client'>{this.props.privateProject.company.text}</p>
              </Col>
              <Col sm={4} xs={6}>
                <h4 className='project__role--title'>ROLE</h4>
                <p className='project__role' dangerouslySetInnerHTML={{ __html: `${this.props.privateProject.role.html}` }} />
              </Col>
            </Row>
            <SliceZone slices={this.props.privateProject.body} components={components} />
            <Col xs={12}>
              <Link to='/projects'><div className='project__cta project__cta--bottom'><FontAwesomeIcon icon={ faArrowLeftLong } className='project__arrow-icon' />&nbsp;BACK TO PROJECTS</div></Link>
            </Col>
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}

export default PrivateProjectContent